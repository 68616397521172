import React, { useContext } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { StaticImage } from 'gatsby-plugin-image';

import { Layout, Seo } from 'components';
import { CommonContainer } from 'components/common-container';
import { appContext } from 'providers/appProvider';
import { Button } from 'components/button';

// import page style
import './index.scss';

const Hero = props => {
  return (
    <div className="hero-container">
      <div className="body-content-container">
        <div className="body-content gutter-all remove-gutter">
          <Row start="md">
            <Col md={12} lg={4}>
              <div className="left-container">
                <StaticImage
                  src="../../images/patient/pages/homepage/sprix-it-for-pain.png"
                  placeholder="none"
                  alt="Sprix IT for pain"
                />
                <p className="hero-main-text">
                  Ketorolac strength in the
                  <br className="hidden-mobile" /> speed of a nasal spray.
                  <br className="hidden-mobile" /> Efficacy within 20-30 minutes
                  <sup>1,2</sup>
                </p>
              </div>
            </Col>
            <Col md={12} lg={4} className="hidden-mobile">
              <StaticImage
                src="../../images/patient/pages/homepage/homepage-hand-spray.png"
                placeholder="none"
                alt="Spray"
              />
            </Col>
            <Col md={12} lg={4}>
              <div className="right">
                <div className="top">
                  <p className="title"> Already have a physician?</p>
                  <p className="content">
                    Have them send your prescription to Scripts Rx.{' '}
                    <br className="hidden-mobile" />
                    <span className="light-blue">
                      $0 copay available to patients with eligible commercial
                      insurance.
                    </span>
                  </p>
                  <Button
                    href="/pdfs/APL-MULT-HCP-Scripts-Rx-Hub-Tear-Pad.pdf"
                    value="Scripts Rx Information"
                    height="32px"
                    external={true}
                  />
                </div>
                <div className="bottom">
                  <p className="title">Need a physician?</p>
                  <p className="bold">
                    SPRIX is now available through Cove,{' '}
                    <br className="hidden-smaller-screen" />a leading
                    telemedicine company. <br />
                    <span className="light-blue">
                      Connect with a doctor online today!
                    </span>
                  </p>
                  <div className="btn-container">
                    <Button
                      href="/patient/get-sprix-now"
                      value="Learn More"
                      height="32px"
                      width="110px"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

const MainContent = () => {
  return (
    <div className="homepage-main-content" id="overview">
      <CommonContainer>
        <h3>
          ASK YOUR HEALTHCARE PROVIDER IF SPRIX<sup>&reg;</sup>{' '}
          <span className="regular">(ketorolac tromethamine)</span> NASAL SPRAY
          IS RIGHT FOR YOU
        </h3>
        <Row bottom="xs">
          <Col md={12} lg={2} className="left-image">
            <StaticImage
              src="../../images/patient/pages/homepage/what-is-sprix.png"
              placeholder="none"
              alt="What is Sprix Nasal Spray?"
            />
          </Col>
          <Col md={12} lg={10} className="right-content-section">
            <h4>WHAT IS SPRIX NASAL SPRAY?</h4>
            <p className="light-blue">
              <span className="bolder">
                SPRIX is the first nasal spray that contains the NSAID
                (nonsteroidal anti-inflammatory drug), ketorolac.
              </span>{' '}
              Since 2010, <br className="hidden-smaller-screen" />
              SPRIX has been approved for the short-term treatment of moderate
              to moderately severe pain for up to 5 days in adult
              <br className="hidden-smaller-screen" /> patients who require
              analgesia at the opioid level. SPRIX is not for use in patients
              less than 2 years of age.
            </p>
          </Col>
        </Row>
        <Row className="empty-row first">
          <Col md={12} lg={2} className="left-image">
            &nbsp;
          </Col>
          <Col md={12} lg={10} className="empty-col right-content-section">
            &nbsp;
          </Col>
        </Row>
        <Row middle="md" className="row-border">
          <Col md={12} lg={2} className="left-image">
            <StaticImage
              src="../../images/patient/pages/homepage/is-sprix-opioid.png"
              placeholder="none"
              alt="Is Sprix an opioid medication or a controlled substance?"
            />
          </Col>
          <Col md={12} lg={10} className="col-border right-content-section">
            <h4>IS SPRIX AN OPIOID MEDICATION OR A CONTROLLED SUBSTANCE?</h4>
            <p className="light-blue">
              <span className="bolder">No.</span> SPRIX is{' '}
              <span className="bolder">NOT</span> an opioid,{' '}
              <span className="bolder">NOT</span> a narcotic, and{' '}
              <span className="bolder">NOT</span> a controlled substance.
            </p>
          </Col>
        </Row>
        <Row className="empty-row">
          <Col md={12} lg={2} className="left-image">
            &nbsp;
          </Col>
          <Col md={12} lg={10} className="empty-col right-content-section">
            &nbsp;
          </Col>
        </Row>
        <Row middle="xs" className="row-border">
          <Col md={12} lg={2} className="left-image">
            <StaticImage
              src="../../images/patient/pages/homepage/how-do-i-get-sprix.png"
              placeholder="none"
              alt="HOW DO I GET SPRIX?"
            />
          </Col>
          <Col md={12} lg={10} className="col-border right-content-section">
            <h4>HOW DO I GET SPRIX?</h4>
            <p className="light-blue">
              <span className="bolder">
                Ask your doctor if SPRIX is right for you.
              </span>{' '}
              When you are prescribed SPRIX, your doctor may also enroll you in
              the SPRIX <br className="hidden-smaller-screen" />
              DIRECT program, which conveniently delivers the medication to your
              home.
            </p>
          </Col>
        </Row>
      </CommonContainer>
    </div>
  );
};

const IndexPage = () => {
  const columnStyle = {
    color: 'white',
    flex: 1,
    marginRight: 15,
  };
  const photoStyle = {
    height: 100,
    backgroundColor: 'gray',
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  };
  const { appConfigs } = useContext(appContext);
  const siteIndications = appConfigs.siteOptions.INDICATIONS;
  return (
    <Layout indication={siteIndications.patient} className="patient-home">
      <Seo indication={siteIndications.patient} />
      <Hero />
      <MainContent />
    </Layout>
  );
};

export default IndexPage;
